import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Components
import Container from "components/container"
import WebinarBody from "components/webinar/body"

// Downloads
import PresentationSlides from "assets/downloads/sexual-health-test/webinar-replay/2022_12_05-Visby Slides-Harnett.pdf"

const StyledContainer = styled(Container)`
  max-width: 1100px;
  overflow: hidden;
  padding: 0px;
`

const BodyLanding = props => {

	return (
		<StyledContainer>
			<WebinarBody
				viewAllowed={props.viewAllowed}
			>
				<div className="flexBox">
					<div className="block block--left">
						<div className="block__content">
							<h2 data-aos="fade-up">Overview</h2>
							<p data-aos="fade-up">
								One of the few silver linings of the COVID-19 pandemic was a downturn in many communicable
								diseases—including sexually transmitted infections, which had been spreading at epidemic levels before 2020.
								Now that the spread of SARS-CoV-2 has slowed considerably, STIs are back with a vengeance.
								</p>
								<p data-aos="fade-up">
								This is occurring at a time when the United States is poorly prepared to handle more cases of chlamydia,
								gonorrhea, syphilis, and other infections, too, as many of the STI specialty clinics that used to shoulder much
								of the assessment-and-treatment burden have shut down. Urgent care stands to be the likely go-to option now, given
								its history of providing quality care in an efficient and convenient manner—and the added benefit of relative
								anonymity as patients can see someone other than their “regular” doctor.
								</p>
								<p data-aos="fade-up">
								With recent updates to published
								treatment guidelines, the question becomes, Are you and your team ready to lead the way?
								</p>
								<p data-aos="fade-up">
								You can assess
								your preparedness and glean new insights from an expert in the field when JUCM hosts a webinar entitled Managing STIs
								in the Urgent Care Setting, led by Glenn Harnett MD, with Josh Russell, MD and Michael Weinstock, MD on
								Monday, December 5, 2–3 PM, Eastern. The presentation will be followed by a live Q&A session.
								JUCM is able to invite you to attend at no charge thanks to the sponsorship of Visby.
							</p>
							<h2 data-aos="fade-up">Learning Objectives</h2>
							<p data-aos="fade-up" className="subtitle">In this video, participants will:</p>
							<ul data-aos="fade-up">
								<li>Review applicable CDC Screening Guidelines for common STIs in young females</li>
								<li>Examine the importance of sexual health education for sexually active adolescent girls</li>
								<li>Evaluate effective STI testing methods and sexual health education strategies</li>
							</ul>
						</div>
					</div>
					{props.featured === true ?
						null
						: (
							<div className="block block--right">
								<div className={(props.pageName === 'display' | props.viewAllowed === false) ? "accessBox show" : "accessBox"}>
									<h3 data-aos="fade-up">Access Includes:</h3>
									<ul data-aos="fade-up">
										<li>Video recording replay</li>
										<li>Presentation Slides (PDF)</li>
									</ul>
								</div>
								<div className={(props.pageName === 'download') ? "downloadBox show showPDF" : "downloadBox"}>
									<h3 data-aos="fade-up">Download</h3>
									<p data-aos="fade-up"><a href={PresentationSlides} target="_blank" rel="noopener noreferrer">Presentation Slides (PDF)</a></p>
								</div>
							</div>
						)
					}
				</div>

				<div className="block block--left">
					<div className="block__content presenter">
						<h2 data-aos="fade-up">About the Presenters</h2>
						<div className="presenterBio" data-aos="fade-up">
							<StaticImage
								src="../../../../assets/images/webinar-replay/harnett.jpg"
								width={200}
								alt="Dr. Glenn Harnett"
								className="presenter__image"
							/>
							<p data-aos="fade-up">Dr. Harnett is the Principal for No Resistance Consulting Group,
								Trustee at UCA Urgent Care Foundation, and on the Editorial Board Member at The Journal of
								Urgent Care Medicine. He has practiced for 20+ years as an emergency medicine and urgent
								care physician. He is an active researcher and has been a Principal Investigator in dozens
								of clinical trials (both Sponsor-initiated and Investigator-initiated). He focuses on
								original research in urgent care medicine.</p>
						</div>
						<div className="presenterBio" data-aos="fade-up">
							<StaticImage
								src="../../../../assets/images/webinar-replay/russell.jpg"
								width={200}
								alt="Dr. Russell"
								className="presenter__image"
							/>
							<p data-aos="fade-up">Dr. Russell is an Emergency and Urgent Care Physician at NorthShore
								University Health System and serves as Associate Editor for Urgent Care : RAP Podcast
								and is the editor-and-chief of JUCM.</p>
						</div>
						<div className="presenterBio" data-aos="fade-up">
							<StaticImage
								src="../../../../assets/images/webinar-replay/weinstock.jpg"
								width={200}
								alt="Dr. Weinstock"
								className="presenter__image"
							/>
							<p data-aos="fade-up">Dr. Weinstock is Director of Research and Medical Education, Adena Health
								Systems; Professor of Emergency Medicine Adjunct, Ohio State University Department of
								Emergency Medicine; Executive Editor, Urgent Care Reviews and Perspectives (UC RAP);
								Medical Director, Ohio Dominican University Physician Assistant studies program; and Senior
								Editor, Clinical Content for JUCM.</p>
						</div>

					</div>
				</div>
			</WebinarBody>
		</StyledContainer>
	)
}

class FullBody extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			viewAllowed: false
		}
	}

	componentDidMount = props => {
		if (localStorage.getItem(this.props.localStorageID)) {
			this.setState({
				viewAllowed: true
			})
		}
	}

	render = () => (
		<BodyLanding
			pageName={this.props.pageName}
			viewAllowed={this.state.viewAllowed}
			localStorageID={this.props.localStorageID}
			featured={this.props.featured}
			featuredDate={this.props.featuredDate}
			featuredTime={this.props.featuredTime}
			featuredCost={this.props.featuredCost}
			featuredUrl={this.props.featuredUrl}
		/>
	)
}

export default FullBody
